<template>
  <v-card elevation="0" class="ma-5">
    <section v-if="eBook">
      <v-row>
        <v-col
          cols="12"
          lg="3"
          md="4"
          sm="6"
          xs="6"
          v-for="(eBook, idx) in eBook.data"
          :key="idx"
        >
          <v-card
            class="radius-card artcle_card__ ma-2 pointer__"
            width="250px"
            height="310px"
            @click.self="toDetail(`/ebook/${eBook.id}/${eBook.visible}`)"
          >
            <div class="temp_img__">
              <img
                :src="`${env}/ebook/upload/${id}/image/${eBook.image}`"
                class="img-fit scale"
                height="150px"
                width="100%"
                alt="cover"
              />
            </div>
            <div class="action__ px-2 d-flex justify-end">
              <v-btn
                fab
                x-small
                dark
                class="mr-2"
                depressed
                color="blue"
                content="View ebook"
                :to="`/ebook/${eBook.id}/${eBook.visible}`"
                v-tippy="{
                  arrow: true,
                  arrowType: 'round',
                  animation: 'fade',
                  theme: 'light',
                  maxWidth: 100,
                }"
                ><v-icon small>mdi-eye</v-icon></v-btn
              >
              <!-- @click="toView(eBook)" -->
              <v-btn
                fab
                x-small
                dark
                class="mr-2"
                @click="updateItem(eBook)"
                depressed
                color="green"
                content="Edit eBook"
                v-tippy="{
                  arrow: true,
                  arrowType: 'round',
                  animation: 'fade',
                  theme: 'light',
                  maxWidth: 100,
                }"
                ><v-icon small>mdi-pencil</v-icon></v-btn
              >
              <v-btn
                fab
                x-small
                dark
                depressed
                @click="deleteItem(eBook.id)"
                color="red"
                content="Delete eBook"
                v-tippy="{
                  arrow: true,
                  arrowType: 'round',
                  animation: 'fade',
                  theme: 'light',
                  maxWidth: 100,
                }"
                ><v-icon small>mdi-delete</v-icon></v-btn
              >
            </div>
            <div class="px-2">
              <h4>{{ eBook.judul }}</h4>
              <span v-snip="3" class="_isi" v-html="eBook.synopsis"> </span>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <section
        class="pa-2 d-flex justify-center"
        style="width: 100%"
        v-if="!eBook.data.length"
      >
        <div>
          <img src="../../assets/img/404.svg" height="100px" alt="404" />
          <h3>No Data Found!</h3>
        </div>
      </section>
    </section>
    <div style="width: 100%" v-if="loading">
      <v-row>
        <v-col cols="3" md="3" v-for="i in 8" :key="i">
          <div>
            <v-skeleton-loader elevation="1" type="card"></v-skeleton-loader>
          </div>
        </v-col>
      </v-row>
    </div>
    <EditEbook
      v-bind:editEbook="editEbook"
      v-bind:item="item"
      @close="closeDialog"
      @refetch="fetch"
    />
  </v-card>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";
import EditEbook from "./Modal/editEbook";

export default {
  components: { EditEbook },
  name: "myeBook",
  data() {
    return {
      editEbook: false,
      item: null,
    };
  },
  computed: {
    ...mapState({
      eBook: (state) => state.library.my_e_book,
      loading: (state) => state.library.loading,
      env: (state) => state.API_URL,
      id: (state) => state.id,
    }),
  },
  mounted() {
    this.fetch();
  },
  methods: {
    toDetail(path) {
      this.$router.push(path);
    },
    fetch() {
      this.$store.dispatch("library/MyEbook");
    },
    closeDialog() {
      this.editEbook = false;
    },
    updateItem(item) {
      this.item = item;
      this.editEbook = true;
    },
    deleteItem(id) {
      Swal.fire({
        title: "Are You Sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        position: "top",
        toast: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store
            .dispatch("library/deleteEbook", { id: id })
            .then((data) => {
              this.fetch();
              Swal.fire({
                icon: "success",
                title: data.message,
                showConfirmButton: false,
                position: "top",
                timer: 3000,
                toast: true,
              });
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
  },
};
</script>

<style scoped>
._isi {
  line-height: 1.2;
}
.artcle_card__ {
  overflow: hidden;
}
.temp_img__ {
  overflow: hidden;
  min-height: 150px;
}
.scale {
  transition: 0.5s;
}
.scale:hover {
  transition: 0.5s;
  transform: scale(1.1);
}
.action__ {
  margin-top: -20px;
}
.pointer__ {
  cursor: pointer;
}
</style>
