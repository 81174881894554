var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"ma-5",attrs:{"elevation":"0"}},[(_vm.eBook)?_c('section',[_c('v-row',_vm._l((_vm.eBook.data),function(eBook,idx){return _c('v-col',{key:idx,attrs:{"cols":"12","lg":"3","md":"4","sm":"6","xs":"6"}},[_c('v-card',{staticClass:"radius-card artcle_card__ ma-2 pointer__",attrs:{"width":"250px","height":"310px"},on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.toDetail(("/ebook/" + (eBook.id) + "/" + (eBook.visible)))}}},[_c('div',{staticClass:"temp_img__"},[_c('img',{staticClass:"img-fit scale",attrs:{"src":(_vm.env + "/ebook/upload/" + _vm.id + "/image/" + (eBook.image)),"height":"150px","width":"100%","alt":"cover"}})]),_c('div',{staticClass:"action__ px-2 d-flex justify-end"},[_c('v-btn',{directives:[{name:"tippy",rawName:"v-tippy",value:({
                arrow: true,
                arrowType: 'round',
                animation: 'fade',
                theme: 'light',
                maxWidth: 100,
              }),expression:"{\n                arrow: true,\n                arrowType: 'round',\n                animation: 'fade',\n                theme: 'light',\n                maxWidth: 100,\n              }"}],staticClass:"mr-2",attrs:{"fab":"","x-small":"","dark":"","depressed":"","color":"blue","content":"View ebook","to":("/ebook/" + (eBook.id) + "/" + (eBook.visible))}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-eye")])],1),_c('v-btn',{directives:[{name:"tippy",rawName:"v-tippy",value:({
                arrow: true,
                arrowType: 'round',
                animation: 'fade',
                theme: 'light',
                maxWidth: 100,
              }),expression:"{\n                arrow: true,\n                arrowType: 'round',\n                animation: 'fade',\n                theme: 'light',\n                maxWidth: 100,\n              }"}],staticClass:"mr-2",attrs:{"fab":"","x-small":"","dark":"","depressed":"","color":"green","content":"Edit eBook"},on:{"click":function($event){return _vm.updateItem(eBook)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1),_c('v-btn',{directives:[{name:"tippy",rawName:"v-tippy",value:({
                arrow: true,
                arrowType: 'round',
                animation: 'fade',
                theme: 'light',
                maxWidth: 100,
              }),expression:"{\n                arrow: true,\n                arrowType: 'round',\n                animation: 'fade',\n                theme: 'light',\n                maxWidth: 100,\n              }"}],attrs:{"fab":"","x-small":"","dark":"","depressed":"","color":"red","content":"Delete eBook"},on:{"click":function($event){return _vm.deleteItem(eBook.id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)],1),_c('div',{staticClass:"px-2"},[_c('h4',[_vm._v(_vm._s(eBook.judul))]),_c('span',{directives:[{name:"snip",rawName:"v-snip",value:(3),expression:"3"}],staticClass:"_isi",domProps:{"innerHTML":_vm._s(eBook.synopsis)}})])])],1)}),1),(!_vm.eBook.data.length)?_c('section',{staticClass:"pa-2 d-flex justify-center",staticStyle:{"width":"100%"}},[_c('div',[_c('img',{attrs:{"src":require("../../assets/img/404.svg"),"height":"100px","alt":"404"}}),_c('h3',[_vm._v("No Data Found!")])])]):_vm._e()],1):_vm._e(),(_vm.loading)?_c('div',{staticStyle:{"width":"100%"}},[_c('v-row',_vm._l((8),function(i){return _c('v-col',{key:i,attrs:{"cols":"3","md":"3"}},[_c('div',[_c('v-skeleton-loader',{attrs:{"elevation":"1","type":"card"}})],1)])}),1)],1):_vm._e(),_c('EditEbook',{attrs:{"editEbook":_vm.editEbook,"item":_vm.item},on:{"close":_vm.closeDialog,"refetch":_vm.fetch}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }